import { useMemo } from 'react';

import { DropdownFoldMenu } from 'components/Dropdown/menus/Fold/DropdownFoldMenu';
import { findAJobDropdownDataByLocale } from 'mappings/FindAJobDropdown/findAJobDropdownDataByLocale';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { NEW_YORK_CITY_COORDINATES } from 'utils/constants/geo';
import { isLocationWithinRadius } from 'utils/geo/isLocationWithinRadius';
import { isLocationInCountry, isNonAdmin } from 'utils/userEnvironment';

import {
  findAJobDropdownDataByResourcesItemType,
  findAJobDropdownDataBySearchItemType,
} from './findAJobDropdownConstants';

type Props = {
  locale: UserLocale;
  trackClickedContext: string;
};

export function FindAJobDropdownMenu({ locale, trackClickedContext }: Props) {
  const { search, resources } = findAJobDropdownDataByLocale[locale];

  const { user } = useUserEnvironment();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showGradfairAd = useMemo(() => {
    if (!user) return false;

    const nonAdmin = isNonAdmin(user);

    const usEnOnly = Boolean(
      isLocationInCountry(user, 'US') && user.locale === 'en',
    );

    const withinRadius = isLocationWithinRadius(
      {
        latitude: user.location.latitude,
        longitude: user.location.longitude,
      },
      NEW_YORK_CITY_COORDINATES,
      50,
    );

    return nonAdmin && usEnOnly && withinRadius;
  }, [user]);

  return (
    <DropdownFoldMenu
      left={{
        title: search.title,
        items: search.items.map((searchItem) => {
          const { iconName, qaId, trackClickedName } =
            findAJobDropdownDataBySearchItemType[searchItem.type];

          return {
            iconName,
            title: searchItem.title,
            href: searchItem.href,
            qaId,
            trackClicked: {
              name: trackClickedName,
              context: trackClickedContext,
            },
          };
        }),
      }}
      right={{
        title: resources.title,
        items: resources.items.map((resourceItem) => {
          const { image, qaId, trackClickedName } =
            findAJobDropdownDataByResourcesItemType[resourceItem.type];

          return {
            image,
            title: resourceItem.title,
            description: resourceItem.description,
            href: resourceItem.href,
            qaId,
            trackClicked: {
              name: trackClickedName,
              context: trackClickedContext,
            },
          };
        }),
      }}
      // TODO the CTA URL is not ready yet
      // bottom={showGradfairAd && <FindAJobDropdownBottomGradfairAd />}
    />
  );
}
