import { IconName } from 'components/Icon/IconName';

import {
  FindAJobDropdownResourcesItemData,
  FindAJobDropdownSearchItemData,
} from './types/FindAJobDropdownData';

export const findAJobDropdownDataBySearchItemType: Record<
  FindAJobDropdownSearchItemData['type'],
  { iconName: IconName; qaId: string; trackClickedName: string }
> = {
  job: {
    iconName: 'briefcase',
    qaId: 'nav-job-link',
    trackClickedName: 'Jobs',
  },
  internship: {
    iconName: 'sign-path',
    qaId: 'nav-internship-link',
    trackClickedName: 'Internships',
  },
  org: {
    iconName: 'building',
    qaId: 'nav-org-link',
    trackClickedName: 'Organizations',
  },
};

export const findAJobDropdownDataByResourcesItemType: Record<
  FindAJobDropdownResourcesItemData['type'],
  { image: string; qaId: string; trackClickedName: string }
> = {
  'salary-explorer': {
    image: 'illustrations/salary-illustration.svg',
    qaId: 'nav-salary-explorer-link',
    trackClickedName: 'Salary Explorer',
  },
  'career-advice': {
    image: 'illustrations/article-illustration.svg',
    qaId: 'nav-career-advice-link',
    trackClickedName: 'Career Advice',
  },
};
